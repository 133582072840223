import { Atom } from ":mods";
import { ROUTES } from "../../const";
export function ChatBtn(props: { mainPage?: boolean }) {
  return (
    <button
      class={`
      fixed bottom-15 right-10 flex flex-row items-center w-fit 
      px-12px cursor-pointer py-10px font-bold text-white bg#p rounded-full
      !text-15px
      `}
      onclick={(e) => {
        e.preventDefault();
        Atom.Route.actions.navigate(ROUTES.contact);
      }}
    >
      <i class="icon-local-microsite:chat-white w-25px h-26px mr-2" />
      Chat With Us
    </button>
  );
}
